<template>
  <div id="address">
    <tmpl_header />

    <div class="container">
      <sidebar act="address" />

      <div class="main">
        <div class="card">
          <div class="operate">
            <a class="btn" @click="createAddr">新增收货地址</a>
            <i
              >您还可以添加<b>{{ 9 - list.length }}</b
              >个收货地址</i
            >
          </div>
          <div class="list">
            <ul>
              <li v-for="(addr, i) in list" :key="addr.addressId">
                <p>
                  <b>{{ addr.addressName }}</b
                  >{{ addr.addressMoblie }}
                </p>
                <p>
                  {{ addr.province.province_name }} {{ addr.city.city_name }}
                  {{ addr.district.district_name }} {{ addr.addressDetail }}
                </p>
                <p :class="{ isDefault: addr.isDefault > 0 }">
                  <i :data-i="i" class="default" @click="setDefault"
                    >默认地址</i
                  >
                  <i :data-i="i" class="edit" @click="editAddr">编辑</i>
                  <i :data-i="i" class="remove" @click="removeAddr">删除</i>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <modal_address
      :show="showAddressModal"
      :data="pickAddr"
      @onCancel="showAddressModal = false"
      @onSubmit="savedAddress"
    />

    <tmpl_footer />
  </div>
</template>
<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import sidebar from "./sidebar.vue";
import modal_address from "../../components/modal/address.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,

      list: [],
      showAddressModal: false,
      pickAddr: null,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    sidebar,
    modal_address,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/address") },
      });

    api.all([this.loadData()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadData: function () {
      return api.post(apis.center.address, {}).then((res) => {
        this.list = res;
      });
    },

    createAddr() {
      this.pickAddr = {};
      this.showAddressModal = true;
    },

    editAddr(e) {
      var i = e.currentTarget.dataset.i;
      this.pickAddr = this.list[i];
      this.showAddressModal = true;
    },

    removeAddr(e) {
      var i = e.currentTarget.dataset.i;
      store.dispatch("toast", "接口待补充" + i);
    },

    savedAddress() {
      this.loadData();
      this.pickAddr = {};
      this.showAddressModal = false;
    },

    setDefault(e) {
      var i = e.currentTarget.dataset.i;
      return api
        .post(apis.center.defaultAddress, {
          addressId: this.list[i].addressId,
        })
        .then(() => {
          this.list.map((item, index) => {
            if (index != i) item.isDefault = "0";
            else item.isDefault = "1";

            return item;
          });
          store.dispatch("toast", "已更改默认地址");
        });
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./address.less");
</style>
