<template>
  <div class="modal" :class="{ show: props.show }">
    <div class="container">
      <div class="mhead">
        <h3>地址信息</h3>
        <a @click="onCancel" class="close"></a>
      </div>
      <div class="mbody">
        <div class="form-group">
          <label class="must">收货人</label>
          <input
            type="text"
            maxlength="10"
            v-model="state.newdata.addressName"
            placeholder="请填写收货人姓名"
          />
        </div>
        <div class="form-group city">
          <label class="must">所在地</label>
          <select
            v-model="state.newdata.addressProvince"
            @change="changeCity"
            data-type="province"
          >
            <option value="" selected disabled hidden>省</option>
            <option
              v-for="item in state.provinces"
              :key="item.provinceId"
              :value="item.provinceId"
            >
              {{ item.provinceName }}
            </option>
          </select>
          <select
            v-model="state.newdata.addressCity"
            @change="changeCity"
            data-type="city"
          >
            <option value="" selected disabled hidden>市</option>
            <option
              v-for="item in state.citys"
              :key="item.cityId"
              :value="item.cityId"
            >
              {{ item.cityName }}
            </option>
          </select>
          <select v-model="state.newdata.addressCounty">
            <option value="" selected disabled hidden>区/县</option>
            <option
              v-for="item in state.districts"
              :key="item.districtId"
              :value="item.districtId"
            >
              {{ item.districtName }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="must">详细地址</label>
          <input
            type="text"
            maxlength="30"
            v-model="state.newdata.addressDetail"
            placeholder="请填写详细的收货地址"
          />
        </div>
        <div class="form-group">
          <label class="must">手机号</label>
          <input
            type="text"
            v-model="state.newdata.addressMoblie"
            placeholder="请填写11位手机号码"
          />
        </div>
        <div class="form-group">
          <label class="">固定电话</label>
          <input
            type="text"
            maxlength="20"
            v-model="state.newdata.addressTelephone"
            placeholder="可选填，格式为：区号-电话号码"
          />
        </div>
        <div class="form-group">
          <label class="">邮政编码</label>
          <input
            type="text"
            maxlength="10"
            v-model="state.newdata.addressZip"
            placeholder="可选填"
          />
        </div>
        <div class="form-group">
          <label class="">地址别名</label>
          <input
            type="text"
            maxlength="10"
            v-model="state.newdata.addressAlias"
            placeholder="可选填，如：公司地址 / 客户地址"
          />
        </div>
      </div>
      <div class="mfoot">
        <a class="cancel" @click="onCancel">取消</a>
        <a class="submit" @click="onSubmit">保存</a>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";
import { reactive, watch, onMounted } from "vue"; // computed

export default {
  props: ["show", "data"],

  setup(props, { emit }) {
    const def = {
      addressId: null,
      addressName: "", // 收件人
      addressProvince: "",
      addressCity: "",
      addressCounty: "",
      addressDetail: "", // 详细地址
      addressMoblie: "",
      addressTelephone: null,
      addressZip: null,
      addressAlias: null,
      isDefault: "0",
    };

    const state = reactive({
      newdata: {},
      provinces: [],
      citys: [],
      districts: [],
    });

    watch(
      () => props.show,
      (newdata) => {
        if (newdata) {
          document.body.classList.add("modal_open");
        } else {
          document.body.classList.remove("modal_open");
        }
      }
    );

    watch(
      () => props.data,
      (newdata) => {
        // console.log("test:", props.data, def);
        if (!newdata || !newdata.addressId) {
          state.newdata = Object.assign({}, def);
        } else {
          const obj = Object.assign({}, def);
          Object.keys(obj).map((item) => {
            obj[item] = props.data[item];
          });
          state.newdata = obj;
        }

        if (newdata && newdata.addressProvince) {
          changeCity({
            currentTarget: { dataset: { type: "province" } },
          }).then(() => {
            if (state.newdata.addressCity) {
              changeCity({
                currentTarget: { dataset: { type: "city" } },
              });
            }
          });
        }
      }
    );

    onMounted(() => {
      api
        .all([loadProvince()])
        .then()
        .catch((err) => {
          console.log(err);
        });
    });

    const loadProvince = () => {
      return api.post(apis.common.province, {}).then((res) => {
        state.provinces = res;
      });
    };

    const changeCity = (e) => {
      const type = e.currentTarget.dataset.type;
      if (!type) return;

      if (type == "province") {
        return api
          .post(apis.common.city, { provinceId: state.newdata.addressProvince })
          .then((res) => {
            state.citys = res;
            state.districts = [];
            state.addressCity = "";
            state.addressCounty = "";
          });
      } else {
        return api
          .post(apis.common.district, { cityId: state.newdata.addressCity })
          .then((res) => {
            state.districts = res;
            state.addressCounty = "";
          });
      }
    };

    const onCancel = () => {
      emit("onCancel", {});
    };

    const onSubmit = () => {
      const moblieReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      const zipReg = /^[0-9]{6}$/;
      const phoneReg = /^((0\d{2,5}-\d{7,8})|(1[34578]\d{9}))$/;
      let params = {};

      if (!state.newdata.addressName) {
        return store.dispatch("toast", "收货人不能为空");
      }

      if (!state.newdata.addressCounty) {
        return store.dispatch("toast", "请选择完整的所在地");
      }

      if (!state.newdata.addressDetail) {
        return store.dispatch("toast", "详细地址不能为空");
      }

      if (!moblieReg.test(state.newdata.addressMoblie)) {
        return store.dispatch("toast", "手机号码不正确");
      }

      if (
        state.newdata.addressTelephone &&
        !phoneReg.test(state.newdata.addressTelephone)
      ) {
        return store.dispatch("toast", "固定电话号码格式不正确");
      }

      if (state.newdata.addressZip && !zipReg.test(state.newdata.addressZip)) {
        return store.dispatch("toast", "邮政编码不正确");
      }

      if (state.newdata.addressId) {
        // 修改
        params = {
          addressId: state.newdata.addressId,
          addressName: state.newdata.addressName,
          provinceId: state.newdata.addressProvince,
          cityId: state.newdata.addressCity,
          countyId: state.newdata.addressCounty,
          detail: state.newdata.addressDetail,
          moblie: state.newdata.addressMoblie,
          telephone: state.newdata.addressTelephone,
          alias: state.newdata.addressAlias,
          zip: state.newdata.addressZip,
        };
        api.post(apis.center.editAddress, params).then(() => {
          store.dispatch("toast", "已保存收货信息");
          emit("onSubmit", state.newdata);
        });
      } else {
        // 新建
        params = {
          addressName: state.newdata.addressName,
          provinceId: state.newdata.addressProvince,
          cityId: state.newdata.addressCity,
          countyId: state.newdata.addressCounty,
          detail: state.newdata.addressDetail,
          moblie: state.newdata.addressMoblie,
          telephone: state.newdata.addressTelephone,
          alias: state.newdata.addressAlias,
          zip: state.newdata.addressZip,
        };
        api.post(apis.center.addAddress, params).then((res) => {
          state.newdata.addressId = res;
          const data = Object.assign({}, state.newdata);
          store.dispatch("toast", "已保存收货信息");
          emit("onSubmit", data);
        });
      }
    };

    return { state, props, changeCity, onCancel, onSubmit };
  },
};
</script>

<style lang="less" scoped>
@import "./modal.less";
@import "../../assets/less/form.less";

.form-group {
  padding: 10px 0px;

  input[type="text"],
  input[type="number"],
  textarea {
    width: 300px;
    padding: 0 12px;
  }
}
</style>
